
import {
    computed,
    defineComponent,
    //PropType,
    reactive,
    ref,
    // watch,
    // toRaw,
} from 'vue';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import useModel from '@/hooks/useModel';
import downloadFile from '@/utils/claim/downloadFile';
import { FileItem } from '@/API/types';
import axios from 'axios';
//import {delAttachment, getUploadFileDataResut} from "@/API/payment";
import * as API from '@/API/payment';
import { AttachmentData } from '@/views/Payment/FlRt/types';
import { DataProps } from '@/views/Payment/DgrcAccounting/type';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import moment from 'moment';
import { useStore } from 'vuex';
type Key = ColumnProps['key'];
type titleProps = 'Risk Dealer' | 'Normal Dealer' | 'Name/Code Changing';
type StatusType = 'start' | 'beforeUpload' | 'checked' | 'imported';
const attachmentColumns = [
    {
        title: 'Attachment',
        dataIndex: 'fileName',
        width: 200,
        slots: { customRender: 'comments' },
    },
    { title: 'Upload Date', dataIndex: 'uploadDate', width: 110 },
    {
        title: 'Operation',
        key: 'operation',
        width: 180,
        slots: { customRender: 'action' },
    },
];
export default defineComponent({
    name: 'App',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        payoutRound: {
            type: String,
            required: true,
            default: '',
        },
    },
    setup(props, context) {
        const { state } = useStore();

        // 复选框
        const selection = reactive<{
            selectedRowKeys: Key[];
            selectedRowData: DataProps[];
            onChange: Function;
        }>({
            onChange: (
                selectedRowKeys: string[],
                selectedRows: DataProps[]
            ) => {
                selection.selectedRowKeys = selectedRowKeys;
                selection.selectedRowData = selectedRows;
            },
            selectedRowKeys: [],
            selectedRowData: [],
        });
        //const myAttachmentData = ref<null | any[]>(null)
        /* const {
      getUploadFileResult
    } = useSalesPayment()*/
        const attachmentData = ref<AttachmentData[]>([]);
        /*watch(()=>props.payoutRound,(newVal,oldVal)=>{
      console.log('进入监听===========',props.payoutRound)
      API.getUploadFileDataResut(props.payoutRound).then((res: any)=>{
        console.log('返回附件列表7777：',res)
        if(res.code === '0'){
          attachmentData.value = res.data
        }
        else{
          message.warning(res.message)
        }
      });
    },{
      immediate:true
    })*/

        const _visible = useModel(props, 'visible');
        const handleCancel = () => {
            _visible.value = false;
        };
        const historyDate = ref(moment());
        const getCurrentData = () => {
            return new Date().toLocaleDateString();
        };
        const rtnCurrendDate = () => {
            return moment(getCurrentData(), 'YYYY-MM-DD');
        };
        //console.log('当前的日期为：',typeof (moment(getCurrentData(), 'YYYY-MM-DD').format('YYYY-MM-DD')))
        // 上传相关
        const uploadStatus = ref<StatusType>('start');
        const curUploadFile = ref<object>({});
        const uploadFileSize = ref<string>('');
        // formData
        const formData = new FormData();
        const curPercent = computed(() => {
            if (
                uploadStatus.value === 'checked' ||
                uploadStatus.value === 'imported'
            ) {
                return 100;
            } else {
                return 0;
            }
        });
        // 上传前校验
        const beforeUpload = (file: FileItem) => {
            const isXlsx =
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXlsx) {
                message.error('Only xlsx format files can be uploaded!');
            }
            const isLt5M = file.size / 1024 / 1024 < state.payment.maxFileSize;
            if (!isLt5M) {
                message.error(
                    `The file size should not be larger than ${state.payment.maxFileSize}MB!`
                );
            }
            return isXlsx && isLt5M;
        };
        // 保存文件数据
        const uploadRequest = (source: any) => {
            if (formData.has('file')) {
                formData.delete('file');
            }
            formData.append('file', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value =
                source.file.size / 1024 > 1024
                    ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
                    : `${Math.floor(source.file.size / 1024)} KB`;
            uploadStatus.value = 'beforeUpload';
        };
        const params = {
            file: formData,
        };
        const downloadModalVisible = ref<boolean>(false);
        const cancelTip = () => {
            downloadModalVisible.value = false;
        };
        const downloadTipsText = ref<string>('');
        const emptyVisible = ref<boolean>(false);
        const emptyTipsText = ref<string>('');
        const fileId = ref('');
        const changeEffectiveFrom = (date: string) => {
            //console.log(historyDate.value)
            API.getUploadFilesByDate(date).then((res: any) => {
                attachmentData.value = res;
            });
        };
        // changeEffectiveFrom(moment(getCurrentData(), 'YYYY-MM-DD').format('YYYY-MM-DD'))
        changeEffectiveFrom(historyDate.value.format('YYYY-MM-DD'));
        const uploadFile = (source: any) => {
            axios({
                method: 'post',
                data: formData,
                url: `/ptapi/dealer/setting/uploadRiskDealerFile`,
                params: params,
            }).then((res) => {
                if (res.data.code !== '0' && res.data.code !== '9011') {
                    // 显示模态窗口
                    downloadModalVisible.value = true;
                    downloadTipsText.value = res.data.message;
                    fileId.value = res.data.data;
                    uploadStatus.value = 'start';
                } else if (res.data.code === '9011') {
                    emptyVisible.value = true;
                    emptyTipsText.value = res.data.message;
                    uploadStatus.value = 'start';
                } else {
                    message.success('File check successfully!');
                    uploadStatus.value = 'checked';
                    changeEffectiveFrom(
                        moment(getCurrentData(), 'YYYY-MM-DD').format(
                            'YYYY-MM-DD'
                        )
                    );
                    context.emit('upload-success');
                    /*API.getUploadFileDataResut(props.payoutRound).then((res: any)=>{
            console.log('返回附件列表9999：',res)
            if(res.code === '0'){
              attachmentData.value = res.data
            }
            else{
              message.warning(res.message)
            }
          });*/
                }
            });
        };
        // 导入接口调用
        const importFile = () => {
            if (!formData.has('file')) {
                message.error('Please select files first!');
                return;
            }
            const params = {
                file: formData,
            };
            axios({
                method: 'post',
                data: formData,
                url: `/ptapi/uploadRedHeadFile?payoutRound=${props.payoutRound}&entity=MBCL`,
                params: params,
            }).then((res: any) => {
                console.log('上传接口返回结果：', res);
                if (res.data.code !== '0' && res.data.code !== '9011') {
                    // 显示模态窗口
                    downloadModalVisible.value = true;
                    downloadTipsText.value = res.data.message;
                    fileId.value = res.data.data;
                    uploadStatus.value = 'start';
                } else if (
                    res.data.code === '9011' ||
                    res.data.code === '500'
                ) {
                    emptyVisible.value = true;
                    emptyTipsText.value = res.data.message;
                    uploadStatus.value = 'start';
                } else {
                    message.success('File check successfully!');
                    changeEffectiveFrom(historyDate.value.format('YYYY-MM-DD'));
                    context.emit('upload-success');
                    uploadStatus.value = 'checked';
                }
            });
        };
        const handleBrowse = () => {
            uploadStatus.value = 'start';
        };
        const handleDownload = () => {
            const params = {
                url: `/ptapi/dealer/setting/downloadRiskDealerTemplate`,
                method: 'get',
                fileName: `${+new Date()}.xlsx`,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };
        const handleDownloadTemplate = () => {
            const params = {
                url: `/ptapi/downloadFileTemplate`,
                method: 'get',
                fileName: `${+new Date()}.xlsx`,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };
        // 上传功能出错后下载模态窗口
        const handleCheckedMsgDownload = () => {
            const downloadParams = {
                fileId: fileId.value,
            };
            const params = {
                url: `/ptapi/downloadFile`,
                method: 'get',
                params: downloadParams,
                fileName: `${+new Date()}.xlsx`,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res) => {
                    downloadModalVisible.value = false;
                    emptyVisible.value = false;
                }
            );
        };
        //#region [附件列表的更新按钮]
        const updateFunc = () => {
            if (selection.selectedRowData.length === 0) {
                message.error('Please select the data first');
                return;
            } else if (
                selection.selectedRowData &&
                selection.selectedRowData.length > 1
            ) {
                message.error('Only one selected');
                return;
            } else {
                const fileId = selection.selectedRowData[0]?.id as string;
                API.updateAttachmentInfo(fileId, props.payoutRound).then(
                    (res: any) => {
                        //console.log('附件列表的更新操作的返回结果：',res)
                        if (res.code === '0') {
                            Modal.info({
                                title: 'Tips',
                                content: 'update success!',
                            });
                            context.emit('upload-success');
                        } else {
                            Modal.error({
                                title: 'Tips',
                                content: res.message,
                            });
                        }
                    }
                );
            }
        };
        //#endregion
        //#region [根据日期查询附件列表]
        //#endregion
        //#region [附件列表的删除按钮]
        const deleteAttachment = (record: any) => {
            API.deleteAttachmentInfo(record.id).then((res: any) => {
                console.log('删除成功！', typeof historyDate.value);
                changeEffectiveFrom(
                    moment(historyDate.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
                );
                //changeEffectiveFrom(historyDate.value)
            });
        };
        //#endregion
        //#region [附件列表的下载按钮]
        const downloadAttachment = (record: any) => {
            const params = {
                url: `/ptapi/downloadFile?fileId=${record.id}`,
                method: 'get',
                fileName: record.fileName,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };
        //endregion
        return {
            _visible,
            selection,
            updateFunc,
            handleCancel,
            beforeUpload,
            uploadRequest,
            uploadFile,
            importFile,
            handleBrowse,
            handleDownload,
            handleDownloadTemplate,
            handleCheckedMsgDownload,
            curUploadFile,
            uploadFileSize,
            curPercent,
            uploadStatus,
            downloadModalVisible,
            downloadTipsText,
            emptyVisible,
            emptyTipsText,
            cancelTip,
            historyDate,
            changeEffectiveFrom,
            attachmentColumns,
            attachmentData,
            getCurrentData,
            rtnCurrendDate,
            deleteAttachment,
            downloadAttachment,
        };
    },
    components: {
        CheckCircleOutlined,
        ExclamationCircleOutlined,
    },
});
