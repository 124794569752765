
import {
  defineComponent,
  ref,
  toRefs,
  onMounted,
  resolveTransitionHooks,
  nextTick,
} from "vue";
import useDGRCAccounting from "@/hooks/payment/DgrcAccounting/useDGRCAccounting";
import {
  UploadOutlined,
  DownloadOutlined,
  FileSearchOutlined,
} from "@ant-design/icons-vue";
import TableWrapper from "../../components/TableWrapper.vue";
import uploadFile from "@/views/Payment/PaymentTracking/A3Tracking/uploadFile.vue";
import { message } from "ant-design-vue";
import GC from "@grapecity/spread-sheets";
import * as ExcelIO from "@grapecity/spread-excelio";
//import "../../node_modules/@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css"
import "@grapecity/spread-sheets-resources-zh";
import previewExcel from "@/utils/payment/previewExcel";
GC.Spread.Sheets.LicenseKey = (ExcelIO as any).LicenseKey =
  "oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn|oasis-uat.mercedes-benz.com.cn|oasis-dev.mercedes-benz.com.cn,858593977834555#B0j3vnNr3SYhRHVj5kbqtkWXRDMW3SNqFFON9GaMJ7YaV7QjVkZENjR7kXZ6RHeBpEN58WT6QUY42WeJ36ZEt4azlnZ9kXR624VtZzcrAlRTJ4LudWSMlDVul5KG3CeNNXOEZVVwInV6I6LUVTO486cwRldIdDepBHNYJ6cDJWbT36L0RXT6BFT7cTWHBHeQNncygXT59UTmh7bLdmNRtiY6RmTHRlciNnZ9ljQGtmSvUXS5J4KyUHMYRUNRRnU5lkYjN5ThJnMYZ5ayN4dMlFVtR6TmJXY5UUUrsyc9M4N9ZjNMplcxllMtRTTxxkeSZTbw3mMn5WW054LRdFelNFZvcHelNkTHFWUTV7VyUjR7hjMOJVaJdXO5plZyI6T5ZDcIVnc6NnZ55kbwFzV9cERiojITJCLiYUMGFTQDZUNiojIIJCLxIDN8ETMyETN0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzATNwkDMgYDM4ATMyAjMiojI4J7QiwiIuNmLt36YuonblJWLzVGZlNmcl5mL6VGZtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuQXY55ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9yZulmbpFmc45ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9ycpNXYvJiOiMXbEJCLig1jlzahlDZmpnInmTYtoXpimrLjl1Yjl7KukfKplL9ilb8plTLimLiOiEmTDJCLiUTN5QzM8czN9MTO5gTN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRR5QoFHTzgDdslEZiB5dDJGarFkR5QmNNFFcvBnYLRkWXBlfzCX";
import GetColumnWidth from "@/utils/payment/getColumnWidth";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const {
      search,
      query,
      reset,
      columnTracking,
      data,
      tableSelection,
      searchRoundList,
      roundList,
      download,
      pagination,
      pageChange,
      sizeChange,
      uploadSuccess,
    } = useDGRCAccounting();
    // 上传相关
    const uploadVisible = ref<boolean>(false);
    const curPayoutRound = ref<string>("9999");
    const upload = () => {
      curPayoutRound.value = query.payoutRound as string;
      //console.log('父页面传递的当前的payoutRound=====',curPayoutRound.value)
      uploadVisible.value = true;
    };
    const previewFlg = ref<boolean>(false);
    const ssHost = ref();
    let workbook: any;
    const preview = async () => {
      if (tableSelection.selectedRowData.length === 0) {
        message.warning("please select data first.");
        return;
      }
      previewFlg.value = true;
      const box = document.getElementById("ssHost");
      if (box) {
        box.innerHTML = "";
      }
      nextTick(() => {
        workbook = new GC.Spread.Sheets.Workbook(ssHost.value);
      });
      const payoutRound = tableSelection.selectedRowData[0].payoutRound;
      const dealerCodelist = [] as any;
      const payoutRoundlist = [] as any;
      //const dealerCode = tableSelection.selectedRowData.dealerCode
      tableSelection.selectedRowData.forEach((item: any) => {
        dealerCodelist.push(item?.dealerCode);
        payoutRoundlist.push(item?.payoutRound);
      });
      const params = {
        url: `/ptapi/previewExcelFile/${payoutRoundlist.join(
          ","
        )}/${dealerCodelist.join(",")}`,
        method: "get",
        fileName: `${+new Date()}.xlsx`,
      };
      const res = await previewExcel(params);
      nextTick(() => {
        new ExcelIO.IO().open((res as any).data, (json: any) => {
          workbook.fromJSON(json);
        });
      });
    };
    const init = () => {
      searchRoundList().then(() => {
        search();
      });
    };
    init();
    const tableWidth = ref(GetColumnWidth(columnTracking));
    return {
      ssHost,
      query,
      search,
      reset,
      columnTracking,
      data,
      tableSelection,
      roundList,
      download,
      upload,
      uploadVisible,
      isPagination: true,
      ...toRefs(pagination),
      pageChange,
      sizeChange,
      preview,
      curPayoutRound,
      previewFlg,
      uploadSuccess,
      tableWidth,
    };
  },
  components: {
    UploadOutlined,
    DownloadOutlined,
    FileSearchOutlined,
    TableWrapper,
    uploadFile,
  },
});
