import { reactive, ref, computed, toRaw } from "vue";
import { message } from "ant-design-vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import downloadFile from "@/utils/claim/downloadFile";
import useMock from "@/utils/payment/useMock";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useThousands from "@/utils/payment/useThousands";
import useTableIndex from "@/utils/payment/useTableIndex";
import { getRoundListByDate, getTrackingLogicList } from "@/API/payment";
import Data from "@/views/Payment/DgrcAccounting/mock";
import { DataProps } from "@/views/Payment/DgrcAccounting/type";
import useOverflowText from "@/utils/payment/useOverflowText";
type Key = ColumnProps["key"];
const useDGRCAccounting = () => {
  const pagination = reactive({
    total: 0,
    pageNumber: 1,
    pageSize: 10,
  });
  const column = ref([
    {
      title: "NO.",
      dataIndex: "no",
      customRender: useTableIndex(pagination),
      width: 70,
    },
    {
      title: "Payout Round",
      dataIndex: "payoutRound",
      width: 200,
    },
    {
      title: "Region",
      dataIndex: "region",
      width: 110,
    },
    {
      title: "Dealer Group",
      dataIndex: "dealerGroup",
      width: 130,
    },
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
      width: 100,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      width: 100,
    },
    {
      title: "Dealer Name (EN)",
      dataIndex: "dealerNameEn",
      customRender: useThousands(),
      align: "right",
    },
    {
      title: "Dealer Name (ZH)",
      dataIndex: "dealerChineseName",
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "amountWo",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 180,
    },
    {
      title: " Payout Amount w VAT",
      dataIndex: "amountW",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 160,
    },
    {
      title: "Date of Payment Statement Received",
      dataIndex: "dateOfPaymentStatementReceived",
    },
    {
      title: "Date of Red Invoice issued",
      dataIndex: "dateRedInvoiceIssued",
    },
    {
      title: "Date of AP approve payment voucher",
      dataIndex: "dateOfRedFaVoucher",
    },
  ]);
  const columnTracking = ref([
    {
      title: "NO.",
      dataIndex: "no",
      customRender: useTableIndex(pagination),
      width: 55,
    },
    {
      title: "Payout Round",
      dataIndex: "payoutRound",
      className: "column-no-wrap",
      width: 200,
    },
    {
      title: "Region",
      dataIndex: "region",
      width: 110,
    },
    {
      title: "Dealer Group",
      dataIndex: "dealerGroup",
      width: 130,
    },
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
      width: 100,
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      width: 100,
    },
    {
      title: "Dealer Name (EN)",
      dataIndex: "dealerNameEn",
      customRender: useOverflowText(),
      width: 180,
    },
    {
      title: "Dealer Name (ZH)",
      dataIndex: "dealerChineseName",
      customRender: useOverflowText(),
      width: 180,
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "amountWo",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 180,
    },
    {
      title: " Payout Amount w VAT",
      dataIndex: "amountW",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 160,
    },
    {
      title: "Date of Payment Statement Received",
      dataIndex: "dateOfPaymentStatementReceived",
      width: 150,
    },
    {
      title: "Date of Red Invoice Notice received",
      dataIndex: "dateRedInvoiceReceived", //"dateOfRedFaNotice",
      width: 150,
    } /*
    {
      title: "Date of AP approve payment voucher",
      dataIndex: "dateOfRedFaVoucher",
    }*/,
  ]);
  const query = reactive({
    payoutRound: "",
    dealerCode: "",
    dealerName: "",
    vendorCode: "",
    region: "",
    dealerGroup: "",
  });
  // 分页

  // 复选框
  const tableSelection = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: DataProps[];
    onChange: Function;
  }>({
    onChange: (selectedRowKeys: string[], selectedRows: DataProps[]) => {
      tableSelection.selectedRowKeys = selectedRowKeys;
      tableSelection.selectedRowData = selectedRows;
    },
    selectedRowKeys: [],
    selectedRowData: [],
  });
  // 表格列表查询
  const res = ref();
  const data = computed(() => {
    return res.value;
  });
  const search = () => {
    const _query = {};
    Object.keys(query).forEach((key) => {
      if (query[key]) {
        _query[key] = query[key];
      }
    });
    tableSelection.selectedRowData = [];
    tableSelection.selectedRowKeys = [];
    getTrackingLogicList(
      _query,
      pagination.pageNumber,
      pagination.pageSize
    ).then((data) => {
      res.value = data;
      // pagination.total = data.totalElements
    });
    getTrackingLogicList(_query, 1, 9999).then((data) => {
      // res.value = data
      pagination.total = data.length;
    });
    // useMock<DataProps>(Data, _query).then((data) => {
    //   res.value = data;
    // });
  };
  // 分页事件
  const pageChange = (val: number) => {
    pagination.pageNumber = val;
    search();
  };
  const sizeChange = (current: number, size: number) => {
    pagination.pageSize = size;
    search();
  };

  // payout round列表查询
  const roundRes = ref();
  const roundList = computed(() => {
    return roundRes.value ? roundRes.value : [];
  });
  const searchRoundList = () => {
    return new Promise((resolve, reject) => {
      getRoundListByDate({
        params: {
          month: "0",
          year: "0",
          type: "DEALER",
        },
      }).then((res: any) => {
        roundRes.value = res;
        query.payoutRound = res[0];
        resolve(res);
      });
    });
  };
  // reset
  const reset = () => {
    for (const key in query) {
      query[key] = "";
      if (key === "payoutRound") {
        query[key] = roundList.value[0];
      }
    }
    pageChange(1);
  };
  // download按钮
  const download = () => {
    const row = tableSelection.selectedRowData;
    if (row.length === 0) {
      message.warning("Please select the data first.");
      return;
    }
    const payoutRoundArr: string[] = [];
    const dealerCodeArr: string[] = [];
    const dealerRegionArr: string[] = [];
    row.forEach((item) => {
      payoutRoundArr.push(item.payoutRound as string);
      dealerCodeArr.push(item.dealerCode as string);
      dealerRegionArr.push(item.region as string);
    });
    const params = {
      url: `/ptapi/overview/trackingLogic/${payoutRoundArr.join(
        ","
      )}/${dealerCodeArr.join(",")}/${dealerRegionArr.join(",")}`,
      method: "get",
      fileName: `${+new Date()}.xlsx`,
    };
    downloadFile(params, "application/vnd-excel; char-set=UTF-8");
  };
  const uploadSuccess = () => {
    search();
  };
  return {
    search,
    query,
    reset,
    column,
    data,
    tableSelection,
    searchRoundList,
    roundList,
    download,
    pagination,
    pageChange,
    sizeChange,
    columnTracking,
    uploadSuccess,
  };
};
export default useDGRCAccounting;
